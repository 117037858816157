import React from 'react';
import {ReactComponent as SemLogo} from "../../images/semfulllogo.svg";
import DeskIll from "../../images/desk-illu.svg"
import axios from "axios";
import '../../css/login.css'
import TextField from "@material-ui/core/TextField";
import LoginHook from "../../hooks/LoginHook";
import {wakeUpBackendServer} from "../routes/Api's";


class Login extends LoginHook {

    render() {
        // axios.get(wakeUpBackendServer);
        const {email, password,} = this.state;
        return (
            <div className="form-container w-100 d-flex">

                <div className="left-form-div">
                    <div className="left-content h-100 mx-auto">
                        <div className="d-flex site-logo">
                            <SemLogo width="200px"/>
                        </div>
                        <div className="form-holder d-flex justify-content-center align-items-center"
                             autoComplete="off">
                            <div className="form w-100">
                                <h3 className="form-title ">Welcome back</h3>
                                <form onSubmit={this.logIn}>
                                    <TextField className="newField" type="email"
                                               name="email" required={true} value={email}
                                               onChange={this.changeEvent} label="Email Address "
                                               fullWidth variant="outlined" margin="normal"
                                               color="secondary"
                                        // autoComplete={}
                                    />
                                    <p id="mailErr" className="text-danger "></p>
                                    <TextField className="newField" type="password"
                                               name="password" required={true} value={password}
                                               onChange={this.changeEvent} label="Password"
                                               fullWidth variant="outlined" margin="normal"
                                               color="secondary"

                                    />
                                    <br/>

                                    <p id="passwordErr" className="text-danger"></p>
                                    <button className="d-block w-100 p-3 my-3 bt" onSubmit={this.logIn}
                                            disabled={!this.state.isEnabled} onKeyPress={this.onEnterPress}>Log In
                                    </button>
                                </form>

                                <p id="err" className="text-danger"></p>

                                <a href="/resetPassword/" className=" forget terms"><p> Forgot your password?</p></a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-ill-div login-right">
                    <div className="d-flex partner-btn justify-content-end align-items-start pr-5">
                        <a href="/">
                            <button className="btn btn-danger">Become a Native</button>
                        </a>
                    </div>
                    <div className="form-img d-flex justify-content-center align-items-center">
                        <img alt="doorIll" src={DeskIll} width="500px"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;